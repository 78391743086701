import {
  BrowserRouter,
  createBrowserRouter,
  useRoutes,
} from "react-router-dom";
import MainRoutes from "./MainRoutes";
import { SectionProvider } from "context";

const routes = [MainRoutes];

export default function ThemeRoutes() {
  return useRoutes(routes);
}

export const Router = () => {
  return <BrowserRouter>
    <SectionProvider>
      <ThemeRoutes />
    </SectionProvider>
  </BrowserRouter>
}

export const router = createBrowserRouter(routes);
