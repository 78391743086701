import "./ContactInformationLinks.scss";
import { FC, ReactElement, ReactNode } from "react";
interface IButtonMaptoProps {
  mapLink: string;
  className?: string;
  children: ReactNode;
}

const ButtonMapto: FC<IButtonMaptoProps> = ({
  mapLink,
  className,
  children,
}): ReactElement => {
  return (
    <a
      href={"map:" + mapLink}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};

export default ButtonMapto;
