import { RouteObject } from "react-router-dom";
import { lazy } from "react";
import { MainLayout } from "layout";


const LoadablePages = {
    HomePage: lazy(() => import("pages/HomePage")),

}
const routes: RouteObject[] = [
    {
        path: "",
        element: (
            <LoadablePages.HomePage />
        ),
        index: true,
    },

];

const getChildRouters = (routes: RouteObject[]): RouteObject[] => {
    const newRoute: RouteObject[] = [...routes]
    routes.forEach((route) => {
        newRoute.push({
            path: route.path,
            element: route.element,
            children: route.children
        })
    })
    return newRoute
}
const MainRoutes: RouteObject = {
    path: "",
    element: <MainLayout />,
    children: getChildRouters(routes),
};

export default MainRoutes;
