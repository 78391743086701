import { Outlet } from "react-router-dom"
import { Header } from "components/header"
import { Newsletter } from "components/news-letter"
import { Footer } from "components/footer"

function MainLayout() {
    return (
        <main id="wrapper" >
            <Header />
            <Outlet />
            <Newsletter />
            <Footer />
        </main>
    )
}

export default MainLayout