import { SVGProps } from "react";

export default function SVGInstagram(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
      >
        <ellipse cx={20.723} cy={20.03} fill="#E2E2E2" rx={20} ry={20.004} />
        <path
          stroke="#030029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M25.723 9.052h-10a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5v-10a5 5 0 0 0-5-5Z"
        />
        <path
          stroke="#030029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M24.723 18.422a4 4 0 1 1-7.914 1.173 4 4 0 0 1 7.914-1.173ZM26.223 13.552h.01"
        />
      </svg>
    );
}
