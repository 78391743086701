import { SVGProps } from "react";
import clsx from "clsx";

type SVGHamburgerMenuProps = SVGProps<SVGSVGElement> & {
    isOpen?: boolean,
}
export default function SVGHamburgerMenu({
    isOpen,
    ...props

}: SVGHamburgerMenuProps) {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
            viewBox="0 0 24 24"
            className={clsx("hamburger-menu", { open: isOpen })}
            {...props}
        >
            <path
                className="line top"
                stroke="#D25DFF"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M3 7h18"
            />
            <path
                className="line middle"
                stroke="#D25DFF"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M3 12h18"
            />
            <path
                className="line bottom"
                stroke="#D25DFF"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M3 17h18"
            />
        </svg>
    );
}
