import { SVGProps } from "react";

export default function SVGArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M14.43 5.93 20.5 12l-6.07 6.07M3.5 12h16.83"
      />
    </svg>
  );
}
