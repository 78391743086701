import "./ContactInformationLinks.scss";
import { FC, ReactElement, ReactNode } from "react";

interface IButtonMailtoProps {
  mailto: any;
  className?: string;
  children?: ReactNode;
}

const ButtonMailto: FC<IButtonMailtoProps> = ({
  mailto,
  className,
  children,
}): ReactElement => {
  return (
    <a
      href={"mailto:" + mailto}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};

export default ButtonMailto;
