import "./Header.scss";
import { useRef, useState } from "react";
import { SVGHambergerMenu, SVGLogoHeader } from "assets/svg";
import clsx from "clsx";
import { useSectionContext } from "context/section-view/creator";
import { motion } from "framer-motion";
import useScrollDirection from "hooks/utils/useScrollDirection";

type NavListValue = "hero" | "services" | "aboutUs" | "projects" | "clientReviews" | "getInTouch";

type NavListDto = {
	id: string,
	name: string,
	value: NavListValue
}

export default function Header() {
	const navRef = useRef<any>(undefined);
	const [isOpen, setIsOpen] = useState(false);
	const { sectionsInView } = useSectionContext()
	const changeOpenHandler = () => {
		const isMenuOpen = !isOpen;
		setIsOpen(!isOpen);
		navRef.current.classList.toggle("show-responsive");
		if (isMenuOpen) {
			document.documentElement.style.overflow = "hidden";
			document.body.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = "";
			document.body.style.overflow = "";
		}
	}
	const scrollDirection = useScrollDirection();

	let navList: NavListDto[] = [
		{ id: "home", name: "Home", value: "hero" },
		{ id: "services", name: "Services", value: "services" },
		{ id: "about-us", name: "About Us", value: "aboutUs" },
		{ id: "projects", name: "Projects", value: "projects" },
		// { id: "client-reviews", name: "Client Reviews", value: "clientReviews" },
		// { id: "contact", name: "Contact", value: "getInTouch" },
	];

	const getActiveLink = (navLink: NavListValue) => {
		return sectionsInView[navLink].inView
	}

	return (
		<motion.header
			className={clsx("header", { "header--scroll": scrollDirection === "down" })}
		>
			<div className="header--logo">
				<SVGLogoHeader />
				<motion.span
					initial={{ opacity: 0, x: 10, scale: 1.2 }}
					animate={{ opacity: 1, x: 0, scale: 1 }}
					transition={{ ease: "easeIn", duration: 1.5 }}
					exit={{ opacity: 0 }}
					className="header--logo--text"
				>
					Sarab.AI
				</motion.span>
			</div>
			<nav ref={navRef} className={clsx("header--nav-menu", { isOpen })}>
				{
					navList.map((nav, idx) => {
						const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
							if (isOpen) {
								setIsOpen(false);
								document.documentElement.style.overflow = "";
								document.body.style.overflow = "";
							}
							if (nav.id !== "services" && nav.id !== "about-us") return;
							event.preventDefault();
							const targetElement = document.getElementById(id);
							if (targetElement) {
								window.scrollTo({
									top: targetElement.offsetTop - 100,
									behavior: 'smooth',
								});
							}
						};
						return <motion.a
							key={idx}
							href={`#${nav.id}`}
							onClick={(e) => handleLinkClick(e, nav.id)}
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ ease: "easeOut", duration: 0.5, delay: idx * 0.1 }}
							className={clsx(`header--nav-menu--item`, {
								"active": getActiveLink(nav.value)
							})}
						>
							{nav.name}
						</motion.a>
					})
				}
				<button
					className="nav-btn nav-close-btn"
					onClick={changeOpenHandler}
				>
					<SVGHambergerMenu
						isOpen={isOpen}
					/>
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={changeOpenHandler}
			>
				<SVGHambergerMenu
					isOpen={isOpen}
				/>
			</button>
		</motion.header >
	);
}
