import "./ContactInfoInputs.scss";
import { ButtonMailto, ButtonMapto, ButtonTelto } from 'components/contact-information-links'
import EmailPNG from "assets/images/contact-information/Email.png";
import PhonePNG from "assets/images/contact-information/Phone.png";
import MapPNG from "assets/images/contact-information/Map.png";
import clsx from "clsx";

type ContactInfoInputsProps = {
  className?: string
}
function ContactInfoInputs({ className }: ContactInfoInputsProps) {
  return (
    <ul className={clsx("contact-info-input", className)}>
      <ButtonMailto mailto={"info@sarab.ai"} className="button-mailto">
        <img src={EmailPNG} alt="Email" />
        <span>info@sarab.ai</span>
      </ButtonMailto>
      <ButtonTelto telto={"+962796506845"} className="button-telto">
        <img src={PhonePNG} alt="Email" />
        <span>+962 79 650 6845</span>
      </ButtonTelto>
      <ButtonMapto mapLink="https:www.googlle.com" className="button-mapto">
        <img src={MapPNG} alt="Map" />
        <span>Amman-Jordan</span>
      </ButtonMapto>
    </ul>
  )
}

export default ContactInfoInputs