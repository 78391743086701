import { SVGProps } from "react";

export default function SVGRefreshCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      style={{animation:"refresh 1s linear infinite"}}
      {...props}
    >
      <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.01 14.51c.18.3.4.58.65.83a4.732 4.732 0 006.68 0 4.71 4.71 0 001.32-2.67M7.34 11.33c.14-.98.57-1.92 1.32-2.67a4.732 4.732 0 016.68 0c.26.26.47.54.65.83"></path>
      <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.82 17.18v-2.67h2.67M16.18 6.82v2.67h-2.67"></path>
    </svg>
  );
}
