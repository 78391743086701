import "./Button.scss";
import clsx from "clsx";
import React, { forwardRef, Ref } from "react";

type ButtonProps = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	mode?: "primary";
	size?: "sm" | "base" | "l" | "xl";
	outline?: boolean;
	rounded?: boolean;
	isLoading?: boolean;
	squared?: boolean;
	children: React.ReactNode;
};

const Button = forwardRef(function Button(
	{
		mode = "primary",
		size = "xl",
		outline = false,
		rounded = false,
		isLoading = false,
		squared = false,
		children,
		...props
	}: ButtonProps,
	ref: Ref<HTMLButtonElement>
) {

	return (
		<button
			{...props}
			className={clsx(
				"btn",
				`btn-${mode}`,
				`btn-${size}`,
				{
					"btn-outline": outline,
					"btn-rounded": rounded,
					"hide-siblings": isLoading,
					squared,
				},
				props.className
			)}
			ref={ref}
		>
			{children}
			{isLoading && <>Loading</>}
		</button>
	);
});
export default Button