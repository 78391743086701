import { SVGProps } from "react";
import { motion } from "framer-motion"
export default function SVGLogoHeader(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={103}
      height={44}
      fill="none"
      {...props}

    >
      <g clipPath="url(#a)">
        <motion.path
          fill="url(#b)"
          d="M.439 14.817h-.22l11.733 23.055s7.768 11.001 19.79 1.025c0 0-3.437 3.005-10.606-9.147C13.973 17.596.439 14.817.439 14.817Z"
          initial={{ opacity: 0, }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 0.1 }}
        />
        <motion.path
          fill="url(#c)"
          d="M38.505.776h16.03L32.491 37.872s-7.768 11.001-19.79 1.025c0 0 3.437 3.005 10.606-9.147C30.477 17.597 38.505.776 38.505.776Z"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 0.1 }}
        />
        <motion.path
          fill="url(#d)"
          d="M61.224.776H77.52L55.113 38.483S47.222 49.667 35 39.522c0 0 3.499 3.054 10.778-9.295C53.058 17.871 61.224.777 61.224.777Z"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 0.5 }}
        />
        <motion.path
          fill="url(#e)"
          d="M85.92.776h16.298L79.81 38.483s-7.891 11.184-20.113 1.039c0 0 3.499 3.054 10.778-9.295C77.755 17.871 85.921.777 85.921.777Z"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 1 }}
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={15.98}
          x2={15.98}
          y1={14.817}
          y2={43.081}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.22} stopColor="#D25DFF" />
          <stop offset={1} stopColor="#170995" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={33.625}
          x2={33.625}
          y1={0.776}
          y2={43.081}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1709FA" />
          <stop offset={0.78} stopColor="#D25DFF" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={56.261}
          x2={56.261}
          y1={0.776}
          y2={43.776}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1709FA" />
          <stop offset={0.78} stopColor="#D25DFF" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={80.958}
          x2={80.958}
          y1={0.776}
          y2={43.776}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1709FA" />
          <stop offset={0.78} stopColor="#D25DFF" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M.219.776h102v43h-102z" />
        </clipPath>
      </defs>
    </svg>

  );
}
