import { SVGProps } from "react";

export default function SVGLinkedin(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      {...props}
    >
      <ellipse cx={20.723} cy={20.03} fill="#E2E2E2" rx={20} ry={20.004} />
      <path
        stroke="#030029"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M24.723 15.052a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-4 0v7h-4v-7a6 6 0 0 1 6-6v0ZM14.723 16.052h-4v12h4v-12ZM12.723 13.052a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
    </svg>
    );
}
