import { SVGProps } from "react";

export default function SVGFacebook(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={41}
            height={41}
            fill="none"
            {...props}
        >
            <circle cx={20.723} cy={20.026} r={20} fill="#E2E2E2" />
            <path
                stroke="#030029"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M25.723 10.026h-3a5 5 0 0 0-5 5v3h-3v4h3v8h4v-8h3l1-4h-4v-3a1 1 0 0 1 1-1h3v-4Z"
            />
        </svg>
    );
}
