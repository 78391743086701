import { SVGProps } from "react";

export default function SVGAIPoweredServices(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none" {...props}>
      <g clipPath="url(#clip0_565_15886)">
        <path d="M32.136 18.736C34.874 18.736 37.6102 18.7192 40.3483 18.7416C43.2431 18.7659 45.4044 20.4046 46.0222 23.0363C46.1416 23.5421 46.1808 24.0759 46.1827 24.5966C46.1939 29.8655 46.1995 35.1326 46.1864 40.4015C46.179 43.3113 44.5104 45.5043 41.847 46.1109C41.2983 46.236 40.7159 46.2602 40.1486 46.2621C34.7882 46.2733 29.4278 46.2751 24.0674 46.2658C20.3588 46.2602 18.0557 43.9421 18.0557 40.2354C18.0557 34.9907 18.0482 29.7442 18.0594 24.4996C18.065 21.7018 19.7485 19.5125 22.369 18.9059C22.9401 18.7734 23.5448 18.7491 24.1327 18.7454C26.8017 18.7286 29.4707 18.7379 32.1397 18.7379L32.136 18.736ZM32.0912 44.1456C34.8498 44.1456 37.6102 44.1474 40.3688 44.1456C42.6384 44.1418 44.0606 42.7495 44.0643 40.5004C44.0718 35.1643 44.0718 29.8301 44.0643 24.494C44.0606 22.2729 42.6402 20.8582 40.4323 20.8563C34.8908 20.8526 29.3494 20.8526 23.808 20.8563C21.628 20.8563 20.1871 22.2524 20.1797 24.4305C20.161 29.8114 20.161 35.1923 20.1797 40.5732C20.1871 42.7457 21.6355 44.14 23.8136 44.1437C26.5722 44.1493 29.3326 44.1437 32.0912 44.1437V44.1456Z" fill="#1709FA" />
        <path d="M51.3697 25.1434C51.5302 25.1434 51.6907 25.1285 51.8475 25.1453C52.8703 25.2591 53.5627 25.0855 54.104 23.9545C54.9532 22.1758 57.1762 21.4908 59.0612 22.1086C60.9687 22.7357 62.139 24.5349 61.9915 26.6179C61.8628 28.4544 60.3491 30.1174 58.5181 30.4347C56.4986 30.7856 54.5464 29.774 53.7774 27.9057C53.5683 27.398 53.3257 27.2245 52.7882 27.2487C51.7859 27.2935 50.7818 27.2711 49.7776 27.258C48.9844 27.2487 48.5197 26.8549 48.5047 26.224C48.4898 25.5633 48.9695 25.1546 49.7963 25.1434C50.3208 25.1359 50.8452 25.1434 51.3697 25.1434ZM57.7379 24.0049C56.5061 23.9937 55.5206 24.9661 55.5169 26.1942C55.5132 27.3999 56.4594 28.376 57.6521 28.4022C58.8821 28.4283 59.8955 27.4652 59.9198 26.2464C59.9459 25.0333 58.9549 24.0179 57.7361 24.0049H57.7379Z" fill="#1709FA" />
        <path d="M12.5852 39.8583C12.4714 39.8583 12.3557 39.8657 12.2437 39.8583C11.1985 39.7743 10.4314 39.8471 9.86398 41.0845C9.05582 42.8446 6.8273 43.4997 4.96088 42.8987C3.02539 42.2753 1.84394 40.4481 2.01192 38.3371C2.15564 36.536 3.65811 34.8936 5.46108 34.5688C7.47309 34.2067 9.4515 35.2053 10.2111 37.0754C10.4388 37.6354 10.7188 37.7697 11.2582 37.7492C12.2157 37.7138 13.1732 37.7343 14.1325 37.7417C15.0191 37.7473 15.5099 38.143 15.4969 38.8224C15.4838 39.4775 15.0079 39.8508 14.1605 39.8601C13.636 39.8657 13.1116 39.8601 12.5871 39.8601L12.5852 39.8583ZM8.48096 38.7701C8.46976 37.5383 7.48055 36.5808 6.24125 36.5995C5.05793 36.6182 4.07992 37.6092 4.07806 38.7925C4.07619 40.0095 5.07473 41.0005 6.2991 40.9949C7.53281 40.9893 8.49216 40.0095 8.48096 38.7683V38.7701Z" fill="#1709FA" />
        <path d="M39.3611 51.9042C39.3611 51.9957 39.3704 52.0871 39.3611 52.1786C39.2193 53.2984 39.4208 54.0618 40.6359 54.6609C42.381 55.5214 43.01 57.7424 42.381 59.5939C41.7352 61.4939 39.9266 62.6455 37.8418 62.4831C36.0407 62.3431 34.3983 60.8444 34.0661 59.0414C33.6965 57.0332 34.6913 55.0659 36.5671 54.2895C37.1177 54.0618 37.2689 53.793 37.2465 53.2462C37.2091 52.2663 37.2278 51.2846 37.239 50.3047C37.2483 49.4723 37.6515 48.9945 38.3122 49.0038C38.9673 49.0131 39.3481 49.4891 39.3593 50.3327C39.3667 50.8572 39.3593 51.3816 39.3593 51.9061L39.3611 51.9042ZM36.1005 58.2071C36.1005 59.4278 37.1027 60.4263 38.3159 60.417C39.5347 60.4058 40.5108 59.4035 40.4978 58.1754C40.4847 56.9734 39.5254 56.0216 38.3178 56.0141C37.0878 56.0048 36.1005 56.9828 36.1005 58.209V58.2071Z" fill="#1709FA" />
        <path d="M24.6385 13.0771C24.6385 12.9633 24.6291 12.8476 24.6385 12.7356C24.7449 11.6754 24.6142 10.9307 23.4029 10.3652C21.6503 9.54586 20.9971 7.31548 21.6037 5.45465C22.2345 3.5229 24.0673 2.34705 26.1764 2.51876C27.9775 2.66621 29.6125 4.17055 29.9335 5.97725C30.2919 7.98739 29.2821 9.97327 27.4139 10.718C26.8371 10.9475 26.7363 11.2443 26.7531 11.7744C26.7849 12.7542 26.7718 13.736 26.7587 14.7159C26.7475 15.5408 26.3276 16.0205 25.6669 16C25.0342 15.9813 24.6553 15.5147 24.6385 14.7196C24.6273 14.1727 24.6366 13.6259 24.6366 13.0771H24.6385ZM27.8991 6.77235C27.8898 5.55544 26.8763 4.5625 25.6631 4.5849C24.478 4.60543 23.5074 5.59277 23.5037 6.78168C23.4981 8.01725 24.4705 8.98966 25.7061 8.9878C26.9435 8.98593 27.9103 8.00979 27.901 6.77235H27.8991Z" fill="#1709FA" />
        <path d="M37.0092 13.1405C37.0092 13.0267 36.998 12.911 37.0092 12.799C37.125 11.7183 37.0018 10.9512 35.7643 10.3689C33.9838 9.53087 33.3305 7.27249 33.9707 5.40607C34.6314 3.47992 36.4717 2.32833 38.5714 2.52244C40.4547 2.69788 42.0057 4.22835 42.3136 6.21796C42.6085 8.11798 41.5241 10.046 39.682 10.7571C39.1967 10.9438 39.0884 11.1883 39.1015 11.6511C39.1295 12.6515 39.1146 13.6557 39.109 14.6579C39.1052 15.5016 38.7301 15.985 38.0824 16.0018C37.3993 16.0204 37.0204 15.5445 37.0111 14.643C37.0055 14.1428 37.0111 13.6407 37.0111 13.1405H37.0092ZM40.2475 6.76669C40.2344 5.54605 39.2247 4.55871 38.0096 4.58484C36.8338 4.6091 35.8465 5.61324 35.8427 6.78722C35.839 8.00227 36.8394 8.99334 38.0638 8.98774C39.3049 8.98214 40.2587 8.0116 40.2475 6.76669Z" fill="#1709FA" />
        <path d="M12.598 27.4952C12.5066 27.4952 12.4151 27.5046 12.3255 27.4952C11.2113 27.3608 10.4423 27.5438 9.84507 28.77C8.99584 30.5151 6.7748 31.1553 4.92517 30.5375C2.99901 29.8917 1.8325 28.057 2.01354 25.9536C2.17219 24.1151 3.6672 22.5324 5.55042 22.2114C7.52883 21.8736 9.46618 22.8758 10.2128 24.7273C10.4386 25.2854 10.7148 25.4179 11.2561 25.3974C12.2565 25.3619 13.2606 25.3787 14.2629 25.3899C15.0337 25.3992 15.5041 25.8173 15.4985 26.4538C15.4929 27.0753 15.0599 27.4635 14.3096 27.4878C13.7403 27.5064 13.171 27.4915 12.6018 27.4915L12.598 27.4952ZM8.48071 26.4836C8.49751 25.2369 7.5475 24.2607 6.30819 24.2532C5.08381 24.2458 4.07781 25.235 4.07968 26.4463C4.07968 27.624 5.05582 28.6263 6.2354 28.658C7.45044 28.6897 8.46578 27.708 8.48258 26.4855L8.48071 26.4836Z" fill="#1709FA" />
        <path d="M51.3977 37.5067C51.4891 37.5067 51.5806 37.4973 51.6702 37.5067C52.7826 37.6373 53.5572 37.4656 54.1525 36.2356C54.9962 34.4924 57.2265 33.8466 59.0706 34.4644C60.9986 35.1083 62.167 36.9411 61.9859 39.0427C61.8292 40.8811 60.3341 42.4676 58.4528 42.7886C56.4781 43.1265 54.5314 42.1223 53.7867 40.2764C53.5627 39.7202 53.2884 39.5821 52.7471 39.6026C51.7467 39.64 50.7426 39.6213 49.7403 39.6101C48.9676 39.6008 48.4973 39.1864 48.501 38.5481C48.5047 37.9285 48.9396 37.5477 49.688 37.5085C49.7328 37.5067 49.7795 37.5067 49.8243 37.5067C50.3488 37.5067 50.8714 37.5067 51.3958 37.5067H51.3977ZM57.7286 40.7468C58.9549 40.7374 59.9403 39.7352 59.9198 38.5201C59.8993 37.3424 58.9063 36.3551 57.7286 36.3439C56.521 36.3327 55.515 37.3349 55.5169 38.5537C55.5169 39.7968 56.4856 40.7561 57.7286 40.7468Z" fill="#1709FA" />
        <path d="M26.9884 51.9342C26.9884 52.0705 27.0015 52.2086 26.9884 52.3448C26.8727 53.3677 27.0519 54.0545 28.1811 54.5958C29.9747 55.4562 30.6578 57.6492 30.0456 59.5381C29.4278 61.4437 27.6323 62.6233 25.555 62.4907C23.7091 62.3732 22.0666 60.8949 21.7232 59.0491C21.3499 57.0333 22.3261 55.0829 24.1962 54.299C24.7599 54.0638 24.9335 53.7951 24.9018 53.209C24.8495 52.2105 24.8775 51.2063 24.8906 50.2041C24.8999 49.4706 25.3049 49.0151 25.9208 49.0039C26.5461 48.9909 26.9474 49.4276 26.9865 50.163C26.9884 50.2078 26.9884 50.2545 26.9884 50.2992C26.9884 50.8461 26.9884 51.393 26.9884 51.9398V51.9342ZM23.7502 58.1774C23.7371 59.4037 24.717 60.4115 25.932 60.4171C27.1415 60.4246 28.1549 59.4149 28.1568 58.2054C28.1568 57.0184 27.1863 56.0348 25.9955 56.0142C24.7543 55.9918 23.7651 56.9456 23.752 58.1774H23.7502Z" fill="#1709FA" />
        <path d="M29.3327 25.7893C30.0401 25.8322 30.6149 26.1365 30.9117 26.7972C32.4459 30.2239 33.9689 33.6563 35.4751 37.0961C35.7401 37.7027 35.4844 38.2757 34.932 38.5295C34.3515 38.7964 33.7841 38.5781 33.4687 37.9304C33.2093 37.3985 33.0282 36.8274 32.7352 36.3178C32.6045 36.092 32.2872 35.8381 32.0502 35.8325C30.2267 35.7933 28.4032 35.7952 26.5797 35.8307C26.3539 35.8344 26.0571 36.0546 25.9265 36.2581C25.695 36.6164 25.5681 37.042 25.3945 37.4377C24.9298 38.5053 24.396 38.8468 23.7017 38.5277C23.0167 38.2122 22.9159 37.6243 23.3769 36.5847C24.4912 34.0669 25.6054 31.5491 26.7216 29.0313C27.0351 28.3239 27.3393 27.6128 27.6641 26.9092C27.9889 26.2055 28.5451 25.8285 29.3364 25.7893H29.3327ZM29.3066 33.9139C29.8758 33.9139 30.4469 33.9101 31.0162 33.9139C31.4642 33.9176 31.6713 33.7757 31.4586 33.2961C30.8613 31.9429 30.2808 30.5804 29.678 29.231C29.6127 29.0873 29.4391 28.9902 29.3178 28.8726C29.2002 28.9996 29.0378 29.1059 28.9706 29.2553C28.3696 30.6066 27.7892 31.9672 27.19 33.3222C26.9959 33.7627 27.1751 33.9139 27.595 33.9139C28.1643 33.9139 28.7354 33.9139 29.3047 33.9139H29.3066Z" fill="#1709FA" />
        <path d="M39.9715 32.2414C39.9715 33.9062 39.9753 35.5692 39.9715 37.2341C39.9678 38.1244 39.5385 38.647 38.8386 38.6395C38.1387 38.6339 37.7244 38.115 37.7225 37.2154C37.7188 33.8428 37.702 30.4683 37.7468 27.0956C37.7524 26.7093 38.0174 26.2016 38.3272 25.9814C38.5792 25.8022 39.1802 25.8321 39.4396 26.0224C39.7289 26.2352 39.9379 26.7317 39.951 27.1124C40.0033 28.8202 39.9734 30.5317 39.9734 32.2395L39.9715 32.2414Z" fill="#1709FA" />
      </g>
      <defs>
        <clipPath id="clip0_565_15886">
          <rect width="60.0093" height="60" fill="white" transform="translate(1.99512 2.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
