import "./Footer.scss";
import { SocialMedia } from "components/social-media";
import { ContactInfoInputs } from "components/contact-info-inputs";
import { SVGLogoFooter } from "assets/svg";

export default function Footer() {
	let links = [
		{
			title: "Home",
			link: "home"
		},
		{
			title: "About Us",
			link: "about-us"
		},
		{
			title: "Services",
			link: "services"
		},
		{
			title: "Projects",
			link: "projects"
		},
		{
			title: "Contact",
			link: "contact"
		}
	];

	return (
		<footer className="footer">
			<div className="footer--logos items-start md:items-center">
				<div className="footer--logos--logo">
					<SVGLogoFooter/>
					<span>Sarab.AI</span>
				</div>
				<SocialMedia className="footer--logos--social-media" />
			</div>
			<div className="footer--quick-links items-start md:items-center">
				<h6 className="footer--quick-links--header">
					Quick Links
				</h6>
				<ul className="footer--quick-links--list">
					{links.map((link,idx) => <a className="a-links" key={idx} href={`#${link.link}`}>{link.title}</a>)}
				</ul>
			</div>
			<div className="footer--contact-info items-start md:items-center">
				<h6 className="footer--contact-info--header">
					Contact Information
				</h6>
				<ContactInfoInputs />
			</div>
		</footer>
	);
}
