import { SVGProps } from "react";

export default function SVGX(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      {...props}
    >
      <ellipse cx={20.723} cy={20.03} fill="#E2E2E2" rx={20} ry={20.004} />
      <g clipPath="url(#a)">
        <path
          stroke="#030029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M31.723 10.052a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1a10.66 10.66 0 0 1-9-4.53s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.507 4.507 0 0 0-.08-.83 7.72 7.72 0 0 0 2.08-3.67v0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M8.723 7.052h24v24h-24z" />
        </clipPath>
      </defs>
    </svg>
    );
}
