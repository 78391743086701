import { createContext, useContext } from "react";

export interface SectionState {
  firstTime: boolean;
  inView: boolean;
}

export interface SectionsInView {
  hero: SectionState;
  services: SectionState;
  aboutUs: SectionState;
  projects: SectionState;
  clientReviews: SectionState;
  getInTouch: SectionState;
}

interface SectionContextValue {
  sectionsInView: SectionsInView;
  setInView: (section: keyof SectionsInView, inView: boolean) => void;
}

const SectionContext = createContext<SectionContextValue | undefined>({
  sectionsInView: {
    hero: {
      firstTime: true,
      inView: false,
    },
    services: {
      firstTime: true,
      inView: false,
    },
    aboutUs: {
      firstTime: true,
      inView: false,
    },
    projects: {
      firstTime: true,
      inView: false,
    },
    clientReviews: {
      firstTime: true,
      inView: false,
    },
    getInTouch: {
      firstTime: true,
      inView: false,
    }
  },
  setInView: () => { }
});

export default SectionContext;

export const useSectionContext = (): SectionContextValue => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error("useSectionContext must be used within a SectionProvider");
  }
  return context;
};
