import { SVGProps } from "react";

export default function SVGArrowBold(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={33}
      fill="none"
      {...props}
    >
      <path
        fill="#909090"
        d="M15.867 7.5c.273 0 .536.105.73.293a.985.985 0 0 1 .302.707v13.59l1.332-1.3c.194-.188.458-.294.732-.294.275 0 .539.106.733.294a.988.988 0 0 1 .304.71c0 .266-.11.522-.304.71l-3.096 3a1.063 1.063 0 0 1-1.589-.156.978.978 0 0 1-.177-.554v-16c0-.265.11-.52.303-.707a1.05 1.05 0 0 1 .73-.293Z"
      />
      <path
        fill="#909090"
        d="M12.77 20.5a1.06 1.06 0 0 1 .733.29l3.097 3a.988.988 0 0 1 .224 1.094 1.003 1.003 0 0 1-.224.326 1.039 1.039 0 0 1-.733.294c-.275 0-.539-.106-.733-.294l-3.097-3a.998.998 0 0 1-.305-.71.973.973 0 0 1 .305-.71 1.035 1.035 0 0 1 .733-.29Z"
      />
    </svg>
  );
}
