import MailchimpSubscribe from "react-mailchimp-subscribe";
import "./Newsletter.scss";
import { SubscribeInput } from "components/subscribe-input";

const url = process.env.REACT_APP_MAILCHIMP_URL;

function Newsletter() {
    return (
        <div className='news-letter'>
            <h2 className="news-letter--text leading-none md:leading-[40px] xl:leading-[58px]">Subscribe to our newsletter</h2>
            <MailchimpSubscribe
                url={url as string}
                render={({ subscribe, status, message }) => (
                    <SubscribeInput
                        subscribe={subscribe}
                        status={status}
                        message={message}

                    />
                )}
            />

        </div>
    )
}

export default Newsletter