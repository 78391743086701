import "./ContactInformationLinks.scss";
import { FC, ReactElement, ReactNode } from "react";

interface IButtonTeltoProps {
  telto: any;
  className?: string;
  children: ReactNode;
}

const ButtonTelto: FC<IButtonTeltoProps> = ({
  telto,
  className,
  children,
}): ReactElement => {
  return (
    <a
      href={"tel:" + telto}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};

export default ButtonTelto;
