import { regex } from "app-contant";
import "./SubscribeInput.scss";
import { SVGArrowRight, SVGRefreshCircle } from "assets/svg";
import { Button } from "components/button";
import { useFormik } from "formik";
import SnackbarUtils from "hooks/utils/SnackbarUtils";
import { useEffect, useRef } from "react";
import { EmailFormFields } from "react-mailchimp-subscribe"
import clsx from "clsx";

type SubscribeInputProps = {
    subscribe: (data: EmailFormFields) => void;
    status: "error" | "success" | "sending" | null;
    message: string | Error | null
}
type MyFormValues = {
    email: string,
}
function SubscribeInput({
    subscribe,
    status,
    message,
}: SubscribeInputProps) {
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validate: (values: MyFormValues) => {
            let errors: Partial<MyFormValues> = {};
            console.log("validate ::: ");

            if (!values.email) {
                errors.email = 'Required';
            }
            else if (values.email && !regex.email.test(values.email)) {
                errors.email = 'Required';
            }
            console.log("errors :::: ", errors);

            return errors
        },
        onSubmit: (values) => {
            onSubscribeHandler()
        },
        validateOnChange:true,
        validateOnBlur:true
    });
    const onSubscribeHandler = async () => {
        subscribe({
            EMAIL: formik.values.email
        });
        if (status === "sending") {
            console.log("sending");
        }
        if (status === "success") {
            console.log("success");
        }
        if (status === "error") {
            console.log("error");
        }

    }
    useEffect(() => {
        if (status === "success") {
            SnackbarUtils.success("Thank you for subscribing", {
                position: "top-right"
            })
        }
        if (status === "error") {
            SnackbarUtils.error("Something went wrong. Please try again", {
                position: "top-right"
            })
        }
    }, [status])

    const containerInputRef = useRef<HTMLDivElement>(null);

    const handleClick = (ev: MouseEvent) => {
        if (!containerInputRef.current) return;
        if (!containerInputRef.current.contains(ev.target as HTMLElement)) {
            formik.setFieldTouched("email",false)        }
    }
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, [containerInputRef.current])

    return (
        <div className='subscribe-input' ref={containerInputRef}>
            <input
                className={clsx("subscribe-input--input",
                    { "subscribe-input--input-error": (!formik.isValid&& formik.touched.email) }
                )}
                id="email"
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
            />
            <Button
                mode="primary"
                className="subscribe-input--button"
                type="submit"
                onClick={formik.handleSubmit as any}
                disabled={status === "sending"}

            >
                <span>Subscribe</span>
                {status === "sending" ? (
                    <SVGRefreshCircle />
                ) : (
                    <SVGArrowRight />
                )}
            </Button>
        </div>
    )
}

export default SubscribeInput