import { SVGProps } from "react";
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer";
export default function SVGLogoFooter(props: SVGProps<SVGSVGElement>) {
  const { inView, ref: SVGLogoFooterRef } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });
  const pathOneVariants = {
    hide: { opacity: 0 },
    visible: { opacity: 1, transition: { ease: "easeIn", duration: 0.1 } }
  }
  const pathTwoVariants = {
    hide: { opacity: 0 },
    visible: { opacity: 1, transition: { ease: "easeIn", duration: 0.5 } }
  }
  const pathThreeVariants = {
    hide: { opacity: 0 },
    visible: { opacity: 1, transition: { ease: "easeIn", duration: 1 } }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="206"
      height="86"
      viewBox="0 0 206 86"
      fill="none"
      ref={SVGLogoFooterRef}
      {...props}
    >
      <g clip-path="url(#clip0_565_11073)">
        <motion.path
          d="M0.511101 28.1008H0.0683594L23.6859 73.6747C23.6859 73.6747 39.3202 95.4208 63.5188 75.7008C63.5188 75.7008 56.601 81.6404 42.1703 57.6184C27.7536 33.5963 0.511101 28.1008 0.511101 28.1008Z" fill="url(#paint0_linear_565_11073)"
          initial={{ opacity: 0 }}
          animate={inView ? "visible" : "hide"}
          variants={pathOneVariants}
        />
        <motion.path
          d="M77.133 0.345703H109.398L65.0268 73.6747C65.0268 73.6747 49.3925 95.4208 25.1938 75.7008C25.1938 75.7008 32.1117 81.6404 46.5423 57.6184C60.9729 33.5963 77.133 0.345703 77.133 0.345703Z" fill="url(#paint1_linear_565_11073)"
          initial={{ opacity: 0 }}
          animate={inView ? "visible" : "hide"}
          variants={pathOneVariants}
        />
        <motion.path
          d="M122.86 0.345703H155.665L110.56 74.882C110.56 74.882 94.677 96.989 70.0771 76.9359C70.0771 76.9359 77.1195 82.9726 91.7715 58.562C106.423 34.1375 122.86 0.345703 122.86 0.345703Z" fill="url(#paint2_linear_565_11073)"
          initial={{ opacity: 0 }}
          animate={inView ? "visible" : "hide"}
          variants={pathTwoVariants}
        />
        <motion.path
          d="M172.572 0.345703H205.376L160.272 74.882C160.272 74.882 144.388 96.989 119.789 76.9359C119.789 76.9359 126.831 82.9726 141.483 58.562C156.135 34.1375 172.572 0.345703 172.572 0.345703Z" fill="url(#paint3_linear_565_11073)"
          initial={{ opacity: 0 }}
          animate={inView ? "visible" : "hide"}
          variants={pathThreeVariants}
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_565_11073" x1="31.7936" y1="28.1008" x2="31.7936" y2="83.9718" gradientUnits="userSpaceOnUse">
          <stop offset="0.22" stop-color="#D25DFF" />
          <stop offset="1" stop-color="#170995" />
        </linearGradient>
        <linearGradient id="paint1_linear_565_11073" x1="67.3097" y1="0.345703" x2="67.3097" y2="83.9718" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1709FA" />
          <stop offset="0.78" stop-color="#D25DFF" />
        </linearGradient>
        <linearGradient id="paint2_linear_565_11073" x1="112.871" y1="0.345703" x2="112.871" y2="85.3457" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1709FA" />
          <stop offset="0.78" stop-color="#D25DFF" />
        </linearGradient>
        <linearGradient id="paint3_linear_565_11073" x1="162.582" y1="0.345703" x2="162.582" y2="85.3457" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1709FA" />
          <stop offset="0.78" stop-color="#D25DFF" />
        </linearGradient>
        <clipPath id="clip0_565_11073">
          <rect width="205.308" height="85" fill="white" transform="translate(0.0683594 0.345703)" />
        </clipPath>
      </defs>
    </svg>

  );
}
