import { ReactNode, useState } from "react";
import SectionContext, { SectionsInView } from "./creator";


interface SectionProviderProps {
  children: ReactNode;
}

const SectionProvider: React.FC<SectionProviderProps> = ({ children }) => {
  const [sectionsInView, setSectionsInView] = useState<SectionsInView>({
    hero: {
      firstTime: true,
      inView: false,
    },
    services: {
      firstTime: true,
      inView: false,
    },
    aboutUs: {
      firstTime: true,
      inView: false,
    },
    projects: {
      firstTime: true,
      inView: false,
    },
    clientReviews: {
      firstTime: true,
      inView: false,
    },
    getInTouch: {
      firstTime: true,
      inView: false,
    },
  });

  const setInView = (section: keyof SectionsInView, inView: boolean) => {
    setSectionsInView((prevState) => {
      if (inView) {
        const updatedSections = Object.keys(prevState).reduce((acc, key) => {
          acc[key as keyof SectionsInView] = {
            ...prevState[key as keyof SectionsInView],
            inView: key === section,
            firstTime: key === section ? false : prevState[key as keyof SectionsInView].firstTime,
          };
          return acc;
        }, {} as SectionsInView);

        return updatedSections;
      } else {
        const allSectionsOutView = Object.values(prevState).every(
          (s) => !s.inView
        );

        if (allSectionsOutView) {
          const closestSection = (Object.keys(prevState) as Array<keyof SectionsInView>)
            .find(key => prevState[key].firstTime);

          if (closestSection) {
            return {
              ...prevState,
              [closestSection]: {
                ...prevState[closestSection],
                inView: true,
                firstTime: false,
              },
            };
          }
        }

        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            inView: false,
          },
        };
      }
    });
  };

  return (
    <SectionContext.Provider value={{ sectionsInView, setInView }}>
      {children}
    </SectionContext.Provider>
  );
};
export default SectionProvider
