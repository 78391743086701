import "./SocialMedia.scss";
import { SVGFacebook, SVGInstagram, SVGLinkedin, SVGX } from "assets/svg";
import { clsx } from "clsx";
import { Fragment } from "react/jsx-runtime";

type SocialMediaProps = {
  className: string;
}
type SocialMediaDto = {
  icon: JSX.Element;
  link: string;
  alt: string;
}

function SocialMedia({
  className
}: SocialMediaProps) {
  const socialMedias: SocialMediaDto[] = [
    {
      icon: <SVGFacebook />,
      alt: "Facebook",
      link: "https:www.google.jo"
    },
    {
      icon: <SVGInstagram />,
      alt: "Instagram",
      link: "https:www.google.jo"
    },
    {
      icon: <SVGLinkedin />,
      alt: "Linkedin",
      link: "https:www.google.jo"
    },
    {
      icon: <SVGX />,
      alt: "X",
      link: "https:www.google.jo"
    }
  ]

  return (
    <div className={clsx("social-media", className)}>
      {
        socialMedias.map((media, idx) => <Fragment key={idx}>{media.icon}</Fragment>)
      }
    </div>
  )
}

export default SocialMedia